.footerWrapper {
  padding: 0 var(--spacing-5);
  background: var(--color-championship-white);
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 99;
  height: 52px;
  border-top: 1px solid var(--color-neutral-50);
  box-shadow: 0px 0px 13px 0px rgba(38, 38, 38, 0.14);
  @media (--tablet-l) {
    display: none;
  }

  & .navListWrapper {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    height: inherit;

    & .footerNavItem {
      text-decoration: none;
      display: flex;
      justify-content: center;
      flex: 1 0 0;
      padding: var(--spacing-2);
      place-items: center;
      color: var(--color-tarmac-grey-700);
      background: var(--color-championship-white);

      & .navListItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-1);
      }

      &.burgerMenuButton {
        background-color: var(--color-championship-white);
        color: var(--footer-background-color);
        padding: var(--spacing-2);
        margin: 0;

        & span {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: var(--spacing-1);
        }

        svg {
          display: block;
        }
      }
    }
  }
}

.logoImgWrapper {
  height: 24px;
  position: relative;
  width: 24px;
}
