.faves {
  position: relative;

  & > span {
    display: none;
    margin-left: var(--spacing-2);
  }

  & .iconWrapper {
    position: relative;
    display: contents;

    & .heartDefault {
      display: block;
    }

    & .heartHover {
      display: none;
    }
  }

  &:hover .iconWrapper {
    & .heartDefault {
      display: none;
    }

    & .heartHover {
      display: block;
    }
  }

  & .counter {
    display: block;
    position: absolute;
    top: 0;
    left: 19px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 1px var(--color-primary-700);
    background: var(--header-nav-counter-bg);
    text-align: center;
    line-height: 14px;
    color: var(--color-neutral-700);
    opacity: 0;
    transform: translateY(5px);
    transition: transform 0.25s ease, opacity 0.25s ease;

    &.active {
      opacity: 1;
      transform: translateY(0);
    }

    @media (--tablet-l) {
      top: 11px;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-2);
  background: none;
  border: none;
  color: var(--header-nav-item-color);
  text-decoration: none;
  min-height: auto;
  cursor: pointer;

  > span {
    height: 24px;
  }
  &.burguerMenu {
    padding-right: 0;
  }

  &.horizontalNavOnly {
    display: none;
  }

  &:hover {
    background: none;
  }

  @media (--tablet-l) {
    height: var(--spacing-12);

    &:hover {
      background-color: var(--header-nav-item-hover-bg);
    }

    &:active {
      background-color: var(--header-nav-item-active-bg);
    }

    &.horizontalNavOnly {
      display: flex;
    }

    &.notHorizontalNav {
      display: none;
    }

    &[data-is-desktop-enabled='false'] {
      display: none;
    }
  }

  @media (--desktop) {
    & > span {
      display: inline;
    }
  }
}

.searchBarWrapper {
  && {
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;

    @media (--tablet-l) {
      top: 72px;
    }

    @media (--desktop-l) {
      max-width: 1203px;
      top: 80px;
    }
  }
}
